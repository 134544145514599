import React, { useState } from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import CurveTop from "../components/svg/curveTop";
import ArrowDown from "../components/svg/arrowDown";
import scrollTo from "gatsby-plugin-smoothscroll";

import axios from "axios";

import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

const ContactPage = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState();

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    const token = await executeRecaptcha("contactForm");
    const form = e.target;
    const data = new FormData(form);
    data.append("g-recaptcha-response", token);
    axios({
      method: "post",
      url: process.env.GATSBY_CONTACT_URL,
      headers: { "Content-Type": "multipart/form-data" },
      data,
    })
      .then((r) => {
        setProcessing(false);
        if (typeof window !== "undefined") {
          window.location = "/success/";
        }
      })
      .catch((r) => {
        setError("An error occurred, please try again later");
        setProcessing(false);
      });
  };

  return (
    <Layout>
      <Seo
        title="Contact"
        description="Contact Madclarity, a media consultancy based in Sydney, Australia."
      />

      <section role="main" className="bg">
        <div className="container">
          <div className="row">
            <div className="sub-hero-content sub-hero">
              <div className="sub-hero-text full">
                <h1
                  data-sal="slide-up"
                  data-sal-delay="200"
                  data-sal-duration="500"
                >
                  We’d love to hear from <span className="blue">you</span>
                </h1>
                <p
                  data-sal="slide-up"
                  data-sal-delay="400"
                  data-sal-duration="500"
                >
                  If you would like to know more about how Madclarity can add
                  value, performance and clarity to your business, we would
                  welcome the opportunity to talk about your goals, challenges
                  or concerns, so we can tailor a proposal to best meet your
                  needs.
                </p>
                <p
                  data-sal="slide-up"
                  data-sal-delay="400"
                  data-sal-duration="500"
                >
                  Call us on{" "}
                  <a href="tel:0292647574" className="bold no-line">
                    +61 (2) 9264 7574
                  </a>
                  , or complete the form below and we’ll get back to you within
                  24 hours.
                </p>
                <div
                  className="btn-row"
                  data-sal="slide-up"
                  data-sal-delay="600"
                  data-sal-duration="500"
                >
                  <button
                    onClick={() => scrollTo("#anchor")}
                    className="btn-scroll"
                  >
                    <ArrowDown />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="curve-btm">
        <CurveTop />
      </div>

      <section className="section-pad" id="anchor">
        <div className="container">
          <div className="row">
            <form
              method="post"
              name="contact"
              id="contact"
              onSubmit={handleOnSubmit}
            >
              <div className="contact-row">
                <div className="contact-2-col">
                  <div className="form-item">
                    <input
                      type="text"
                      name="usersname"
                      id="usersname"
                      required
                    />
                    <label htmlFor="usersname" className="form-label">
                      Full name
                    </label>
                  </div>
                </div>
                <div className="contact-2-col">
                  <div className="form-item">
                    <input type="text" name="phone" id="phone" required />
                    <label htmlFor="phone" className="form-label">
                      Phone number
                    </label>
                  </div>
                </div>
              </div>

              <div className="contact-row">
                <div className="contact-full-col">
                  <div className="form-item">
                    <input type="email" name="email" id="email" required />
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                  </div>
                </div>
              </div>

              <div className="contact-row">
                <div className="contact-full-col">
                  <div className="form-item">
                    <textarea required name="message" id="help" rows="4" />
                    <label htmlFor="help" className="form-label">
                      How can we help?
                    </label>
                  </div>
                  <input
                    type="text"
                    name="_gotcha"
                    style={{ display: "none" }}
                  />
                </div>
              </div>

              <div className="contact-row">
                <button type="submit" className="btn btn-lg">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </Layout>
  );
};

const ContactPageWithRecaptcha = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LfQ0o0iAAAAAOUemLp2U1ayXkeRxy2QSkbw_2I5">
      <ContactPage />
    </GoogleReCaptchaProvider>
  );
};

export default ContactPageWithRecaptcha;
